$colorBlue: #0075b6;
$colorWhite: #fafafa;
$colorWhiteTransparent: #ffffffb9;
$colorRed: #ff4848;

:export {
    colorBlue: $colorBlue;
    colorWhite: $colorWhite;
    colorWhiteTransparent: $colorWhiteTransparent;
    colorRed: $colorRed;
}

@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@200;500;700&display=swap');

@mixin centerThis {
    display: flex;
    justify-content: center;
    align-items: center;
}

@mixin clickable {
    cursor: pointer;
    text-decoration: none;
}

* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

body {
    background-color: $colorWhite;
    font-family: 'Raleway', sans-serif;
    overflow-x: hidden;
}

a {
    color: inherit;
    text-decoration: none;
}

.splitter-vertical {
    width: 1px;
    height: 300px;
    border-left: 1px solid $colorBlue;
}


/* width */
::-webkit-scrollbar {
    width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
background: #f1f1f1; 
}

/* Handle */
::-webkit-scrollbar-thumb {
background: #0075B6; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
background: #015886; 
}

*:focus {
outline: none;
}






























//Header

.header {
    position: fixed;
    top: 0;
    width: 100vw;
    @include centerThis();
    z-index: 10;
    font-size: 20px;
    padding-left: 15.5vw;
    padding-right: 15.5vw;
}

.header-logo {
    @include centerThis();
    justify-content: flex-start;
    @include clickable();
    width: 30%;
    img {
        height: 70px;
    }
}

.header-menu {
    width: 70%;
    height: 100px;
    @include centerThis();
    justify-content: space-between;
    flex-direction: row;
    color: white;
}

.header-hamburger_button {
    display: none;
}

.header-menumobile {
    display: none;
    overflow-y: scroll;
}

.header-menu_button {
    @include clickable();
}

.header-menu_mobilebutton {
    @include clickable();
}







//Intro

.intro {
    height: 100vh;
    width: 100%;
    @include centerThis();
    background-color: black;
    overflow-y: hidden;

}

.intro-videoholder {
    width: 100%;
    height: 100%;
}

.intro-videoholder video {
    background-position: center;
    background-size: cover;
    opacity: 0.7;
    object-fit: cover;
    width:100%;
    height:100%;
}

.intro-text {
    position: absolute;
    text-align: center;
    z-index: 2;
    color: white;
    margin-top: 35vh;
    // text-shadow: 4px 4px 20px rgba(0, 0, 0, 0.651);
}

.intro-text_1 {
    font-size: 100px;
    font-weight: 500;
}

.intro-text_2 {
    font-size: 61px;
    font-weight: 200;
}








//Upcoming

.upcoming {
    margin-top: 100px;
    width: 100vw;
    background-color: $colorWhite;
}

.upcoming-header {
    @include centerThis();
    justify-content: space-between;
    padding-left: 14vw;
    padding-right: 14vw;
}

.upcoming-header_text {
    font-size: 2.7vw;
    font-weight: 300;
    margin-right: 30px;
}

.upcoming-header_line {
    height: 1px;
    width: 48vw;
    border-bottom: solid $colorBlue 1px;
}

.upcomingcontent {
    @include centerThis();
    justify-content: space-between;
    margin-top: 50px;
    padding-left: 14vw;
    padding-right: 14vw;
    img {
        width: 100%;
    }
}

.upcoming-videoholder {
    width: 100vw;
    // video {
    //     width: 100%;
    // }

}











//About

.about {
    margin-top: 120px;
    width: 100%;
}

.about-header {
    @include centerThis();
    justify-content: space-between;
    padding-left: 14vw;
    padding-right: 14vw;
}

.about-header_text {
    font-size: 2.7vw;
    font-weight: 300;
    margin-right: 30px;
}

.about-header_line {
    height: 1px;
    width: 60vw;
    border-bottom: solid $colorBlue 1px;
}

.aboutcontent {
    @include centerThis();
    height: 350px;
    margin-top: 4vh;
    padding-left: 5vw;
    padding-right: 5vw;
}

.about-text {
    width: 41vw;
    margin-right: 2vw;
    font-size: 1em;
    height: 100%;
    p {
        text-align: justify;
        line-height: 1.7em;
    }
}

.about-image {
    height: 100%;
    img {
        margin-left: 2vw;
        width: 27vw;
    }
}




//Games

.games {
    margin-top: 100px;
    width: 100%;
    background-color: $colorWhite;
    @include centerThis();
    flex-direction: column;
    justify-content: flex-start;
}

.games-header {
    @include centerThis();
    justify-content: space-between;
    padding-left: 14vw;
    padding-right: 14vw;
}

.games-header_text {
    font-size: 2.7vw;
    font-weight: 300;
    margin-right: 30px;
}

.games-header_line {
    height: 1px;
    width: 59vw;
    border-bottom: solid $colorBlue 1px;
}

.gamescontent {
    margin-top: 4vh;
    width: 100%;    
    img {
        width: 23.7vw;
        margin: 0px 1px -2px 1px;
    }
}

.gamescontent-tabs {
    width: 100vw;
    @include centerThis();
    justify-content: flex-start;
    padding-left: 14vw;
    padding-right: 14vw;
    margin-bottom: 50px;
}

.gamescontent-tabs_button {
    @include clickable();
    @include centerThis();
    width: 140px;
    height: 50px;
    border: 1px solid $colorBlue;
    border-radius: 10px;
    margin-left: 5px;
    margin-right: 5px;
}

.activeTab {
    background-color: $colorBlue;
    color: white;
    border: 0px;
    border-radius: 10px;
}

.gamescontent-game, .gamescontent-animation, .gamescontent-defence, .gamescontent-vr {
    @include centerThis();
    width: 100vw;
}

.gamescontent-game_galleryholder {
    @include centerThis();
    flex-wrap: wrap;
    width: 85vw;

}









//Team

.team {
    margin-top: 100px;
    width: 100%;
    background-color: $colorWhite;
    @include centerThis();
    flex-direction: column;
    justify-content: flex-start;
}

.team-header {
    @include centerThis();justify-content: space-between;
    padding-left: 14vw;
    padding-right: 14vw;
}

.team-header_text {
    font-size: 2.7vw;
    font-weight: 300;
    margin-right: 30px;
}

.team-header_line {
    height: 1px;
    width: 47vw;
    border-bottom: solid $colorBlue 1px;
}

.teamcontent {
    @include centerThis();

    flex-direction: column;
    justify-content: space-between;
}

.teamcontent_showcase {
    @include clickable();
    user-select: none;
    height: 600px;
    width: 100vw;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    padding-left: 14vw;
    padding-right: 14vw;
    img {
        position: relative;
        transform-origin: center center;
    }
}

.teamcontent_showcase-mobile {
    @include clickable();
    user-select: none;
    display: none;
    height: 600px;
    width: 100vw;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;

    img {
        position: relative;
        transform-origin: center center;
    }
}

.teamcontent_text {
    margin-top: 50px;
    margin-bottom: 50px;
    width: 70vw;
    line-height: 1.7em;
}




//Careers

.careers {
    margin-top: 100px;
    width: 100%;
    background-color: $colorWhite;
}

.careers-header {
    @include centerThis();justify-content: space-between;
    padding-left: 14vw;
    padding-right: 14vw;
}

.careers-header_text {
    font-size: 2.7vw;
    font-weight: 300;
    margin-right: 30px;
}

.careers-header_line {
    height: 1px;
    width: 60vw;
    border-bottom: solid $colorBlue 1px;
}

.careerscontent {
    width: 100wv;
    @include centerThis();
}

.careerscontent-no {
    margin-top: 100px;
    padding: 20px;
    background-color: #f7f7f7;
    box-shadow: 0px 0px 20px -9px $colorBlue;
    width: 350px;
    height: 200px;
    @include centerThis();
    text-align: center;
    border: solid 1px #dbdbdb;
    border-radius: 10px;
}





//Contact

.contact {
    margin-top: 100px;
    width: 100vw;
    background-color: $colorWhite;
    margin-bottom: 20vh;
}

.contact-header {
    @include centerThis();
    justify-content: space-between;
    padding-left: 14vw;
    padding-right: 14vw;
}

.contact-header_text {
    font-size: 2.7vw;
    font-weight: 300;
    margin-right: 30px;
}

.contact-header_line {
    height: 1px;
    width: 60vw;
    border-bottom: solid $colorBlue 1px;
}

.contactcontent {
    @include centerThis();
    justify-content: space-between;
    margin-top: 50px;
    padding-left: 14vw;
    padding-right: 14vw;
}

.contact_contactcontent-address {
    width: 25vw;
    height: 25vw;
    @include centerThis();
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    font-weight: 200;

    // * {
    //     font-size: 1.5em;
    // }
}

.contactcontent-social {
    @include centerThis();
    justify-content: flex-start;
    width: 100%;
    margin-left: -9px;
    * {
        @include clickable();
        margin-left: 8px;
        margin-right: 8px;
    }
}

.contact_contactcontent-map {
    width: 25vw;
    height: 25vw;
}











.reserved {
    width: 100vw;
    font-size: 12px;
    margin-bottom: 2vw;
    text-align: center;
}
















































@media only screen and (max-width: 1200px) {
    
    ::-webkit-scrollbar {
        width: 0px;
    }

    .header {
        justify-content: space-between;
        padding-left: 0;
        padding-right: 0;
    }

    .header-logo {
        margin: 10px;
        img {
            height: 45px;
        }
    }

    .header-menu {
        display: none;
    }

    .header-hamburger_button {
        @include centerThis();
        margin-right: 5vw;
        color: black;
        z-index: 2;
    }

    .header-menumobile {
        position: fixed;
        top: 65px;
        width: 100vw;
        z-index: 10;
        @include centerThis();
        flex-direction: column;
        background-color: $colorWhite;
    }

    .header-menu_mobilebutton {
        margin-top: 15px;
        margin-bottom: 15px;
        width: 100vw;
        text-align: center;
        font-size: 1.2em;
    }

    .intro-text_1 {
        font-size: 8vw;
    }

    .intro-text_2 {
        font-size: 5vw;
    }

    .upcoming-header_text {
        font-size: 6.5vw
    }

    .about {
        margin-top: 60px;
    }

    .about-header_line {
        width: 70vw;
    }
    
    .about-header_text, .games-header_text, .team-header_text, .careers-header_text, .contact-header_text {
        font-size: 6.5vw;
    }

    .aboutcontent {
        flex-direction: column;
        height: 100%;
        margin-top: 0;
        padding-left: 14vw;
        padding-right: 14vw;
    }

    .about-text {
        width: 100%;
        font-size: 1em;
        margin-top: 2vw;
        margin-right: 0;
    }

    .about-image {
        width: 100%;
        justify-content: center;

        img {
            width: 100%;
            margin-left: 0;
            margin-top: 20px;
        }
    }

    .games {
        margin-top: 60px;
    }

    .games-header {
        padding-left: 14vw;
        padding-right: 14vw;
    }

    .games-header_line {
        width: 40vw;
    }

    .gamescontent-tabs {
        padding-left: 10vw;
        padding-right: 10vw;
    }

    .gamescontent-tabs_button {
        width: 110px;
        height: 50px;

    }

    .gamescontent {
        img {
            width: 77vw;
            margin: 0;
        }
    }

    .team {
        margin-top: 60px;
    }

    .team-header_text {
        margin-right: 0px;
    }

    .team-header_line {
        width: 40vw;
    }
    
    .teamcontent_showcase {
        display: none;
    }
    
    .teamcontent_showcase-mobile {
        display: block;
        width: 100vw;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        padding-left: 10vw;
        padding-right: 10vw;
        img {
            position: relative;
            transform-origin: center center;
        }
    }

    .teamcontent_text {
        width: 70vw;
    }


    .careers {
        margin-top: 0px;
    }

    .careerscontent-no {
        width: 273px;
    }

    .contact {
        margin-bottom: 10vh;
    }

    .contactcontent {
        flex-direction: column;
    }

    .contact_contactcontent-map {
        width: 70vw;
        height: 70vw;
        margin-top: 50px;
    }

    .contact_contactcontent-address {
        @include centerThis();
        font-weight: 500;
        height: 120vw;
        width: 100vw;
        a {
            width: 72vw;
            flex-direction: column;
            margin-bottom: 30px;
        }
        div {
            width: 72vw;
            margin-bottom: 30px;
        }
    }

    .contactcontent-social {
        @include centerThis();
        margin-top: 20px;
    }

    .splitter-vertical {
        display: none;
    }
}